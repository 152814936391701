import React from "react";
import photoGridData from "../content/files/photogrid/new_grid_compressed/structure.json";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import styled from "styled-components";

// const Wrapper = styled.div`
//   display: flex;
//   width: 100%;
//   gap: 5px;
//   place-items: center;

//   @media (max-width: 750px) {
//     grid-template-columns: 100%;
//     height: auto;
//     margin-top: 30px;
//   }
// `;

const StyledImage = styled(LazyLoadImage)`
  width: auto;
  padding: 5px;
  width: 100%;
  %filter: grayscale(100%);
`;

const Process = () => {
  return (
    <div>
      <div style={{ height: "300px" }}></div>
      <h1>Journal</h1>

      <p>
        These are snapshots of people who build, machines that make, things that
        are built, places that are experienced.
      </p>
      <div style={{ height: "100px" }}></div>
      <ResponsiveMasonry columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3 }}>
        <Masonry gutter="10px">
          {photoGridData.map((imgData) => {
            return (
              <StyledImage
                src={require(`../content/files/photogrid/new_grid_compressed/600/${imgData}.webp`)}
                effect="blur"
              />
            );
          })}
        </Masonry>
      </ResponsiveMasonry>
      <div style={{ height: "100px" }}></div>

      <div style={{ height: "100px" }}></div>
    </div>
  );
};

export default Process;
