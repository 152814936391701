import React from "react";
import ReactDOM from "react-dom/client";
import Process from "./Components/Process";
import App from "./App";
import Aboutv2 from "./Components/Aboutv2";
import ProjectGallery from "./Components/ProjectGallery";
import Project from "./Components/Project";

import { createBrowserRouter, Link, RouterProvider } from "react-router-dom";

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: (
      <div>
        <h1>This page does not exist</h1>
        <Link to="/">go back</Link>
      </div>
    ),
    children: [
      {
        path: "/",
        loader: async () => {
          const mdFile = require("./content/files/about/About.md");
          const file = await fetch(mdFile);
          return file;
        },
        element: <Aboutv2 />,
      },
      {
        path: "/journal",
        element: (
          <div>
            <Process />
          </div>
        ),
      },
      {
        path: "/sketchbook",
        element: <ProjectGallery />,
      },
      {
        path: "sketchbook/:project",
        loader: async (params) => {
          //const test = await params.project;
          const mdFile = require("./content/projects/vespidae/vespidae.md");
          const file = await fetch(mdFile);
          return file;
        },
        element: <Project />,
      },
    ],
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  //<React.StrictMode>
  <RouterProvider router={router} />
  //</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
