import React from "react";
import styled from "styled-components";
import ReactVivus from "react-vivus";
import svg from "../files/frikk_arrow.svg";
import { NavLink } from "react-router-dom";

//investigage conditional rendering to remove vivus glitch with double components: https://react.dev/learn/conditional-rendering
//for now i'm removing strict mode in index.js

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 70% 30%;
  margin-top: 20px;
  align-items: center;
`;

const StyledMenu = styled.div`
  display: flex;
  justify-content: flex-end;
  transition: opacity 0.3s ease;
  &:hover {
    opacity: 0.6;
  }

  * {
    font-size: 1.2rem; // Set the font-size for all elements
    font-weight: 300;
  }
`;

const StyledLink = styled(NavLink)`
  position: relative;
  margin-left: 20px;
  text-decoration: none;

  &:hover {
    opacity: 1;
  }

  &.active {
    &:after {
      content: "";
      position: absolute;
      bottom: -5px;
      left: 0;
      right: 0;
      height: 2px;
      background-color: ${({ theme }) => theme.action};
    }
  }
`;

const LogoContainer = styled.div`
width: 50px;
  &:hover {
    .prefix__cls-1 {
      stroke: ${({ theme }) => theme.action};
    }
`;
// const DarkText = styled.span`
//   cursor: pointer;
//   text-decoration: ${(props) => (props.isDarkMode ? "none" : "underline")};
//   user-select: none; // Add this line
// `;

// const LightText = styled.span`
//   cursor: pointer;
//   text-decoration: ${(props) => (props.isDarkMode ? "underline" : "none")};
//   user-select: none; // Add this line
// `;

// const DarkModeSwitch = ({ isDarkMode, onToggle, className }) => (
//   <div onClick={onToggle} className={className}>
//     <DarkText isDarkMode={isDarkMode}>dark</DarkText>
//     <span> / </span>
//     <LightText isDarkMode={isDarkMode}>light</LightText>
//   </div>
// );

// const StyledDarkModeSwitch = styled(DarkModeSwitch)`
//   margin-left: 20px;
// `;

const Header = ({ switchState, state }) => {
  return (
    <Wrapper>
      <LogoContainer>
        {" "}
        <ReactVivus
          id="foo"
          option={{
            file: svg,
            type: "sync",
            animTimingFunction: "EASE_OUT_BOUNCE",
            duration: 80,
          }}
          style={{ stroke: "gray" }}
        />
      </LogoContainer>
      <StyledMenu>
        {" "}
        <StyledLink to="/">about</StyledLink>
        <StyledLink to="/journal">journal</StyledLink>
        {/* <StyledDarkModeSwitch isDarkMode={!state} onToggle={switchState} /> */}
      </StyledMenu>
    </Wrapper>
  );
};

export default Header;
