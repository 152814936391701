import styled, { createGlobalStyle } from "styled-components";
import iAWriterQuattroV from "../../files/fonts/quattro/iAWriterQuattroV.ttf";
import iAWriterQuattroVItalic from "../../files/fonts/quattro/iAWriterQuattroV-Italic.ttf";
import SpaceMonoRegular from "../../files/fonts/Space_Mono/SpaceMono-Regular.ttf";
//macbook pro is 1440 x 820 ish

const GlobalStyles = createGlobalStyle`

@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');

@font-face {
    font-family: 'Quattro';
    src: local("Quattro"), url(${iAWriterQuattroV})format("truetype-variations");
}
@font-face {
  font-family: 'QuattroItalic';
  src: local("QuattroItalic"), url(${iAWriterQuattroVItalic})
}

@font-face {
  font-family: 'SpaceMono-Regular';
  src: local("SpaceMono-Regular"), url(${SpaceMonoRegular})
}

body {
  background: ${({ theme }) => theme.background};
  color: ${({ theme }) => theme.text};
    margin: 0;
    padding: 0;
    //font-family: 'SpaceMono-Regular', sans-serif;
    font-family: -apple-system,BlinkMacSystemFont,SF Pro Text,avenir next,avenir,segoe ui,helvetica neue,helvetica,Cantarell,Ubuntu,roboto,noto,arial,sans-serif;
  }

  h1{
    margin-bottom: 40px;
    font-size: 1.5rem;
    color: ${({ theme }) => theme.h1_color};
  }
  h2{
    font-size: 1.4em;
    color: ${({ theme }) => theme.text};
  }
  h3{

    font-size: 1.2em;
    color: ${({ theme }) => theme.text};
  }
  h4{
    font-size: 1em
  }
  p{
    margin-bottom: 36px;
    font-size: 1.4rem;
    opacity: ${({ theme }) => theme.opacity};
    //font-size: 20px;
  }

  a{
    //font-size: calc(.7em + 0.390625vw);
    //text-decoration: none;
    text-decoration: underline;
    color: inherit;
  }

  // a:link{
  //   color: #69aaff;
  // }

  a:hover{
    color: ${({ theme }) => theme.action};
  }
  a:visited{
    color: inherit
  }
}
`;

export const MetaWrapper = styled.div`
  min-height: 100vh;
  background: ${({ theme }) => theme.background};
  color: ${({ theme }) => theme.text};
`;

export const Wrapper = styled.div`
  max-width: 1600px;
  margin: 0 auto;
  min-height: 100vh;
  padding: 0 3em;

  @media (max-width: 1440px) {
    padding: 0 3em;
  }

  /* Styles for MacBook 13" and smaller screens */
  @media screen and (max-width: 1440px) {
  }

  /* Styles for iPad and smaller screens */
  @media screen and (max-width: 1024px) {
  }

  /* Styles for iPhone and smaller screens */
  @media screen and (max-width: 768px) {
    padding: 0 1em;
  }
`;

export default GlobalStyles;
