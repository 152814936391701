import React from "react";
import { Link } from "react-router-dom";

export const LinkRenderer = ({ href, children }) => {
  const isInternalLink = /^\/(?!\/)/.test(href);

  if (isInternalLink) {
    return <Link to={href}>{children}</Link>;
  } else {
    return (
      <a href={href} target="_blank" rel="noopener noreferrer">
        {children}
      </a>
    );
  }
};
