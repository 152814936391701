export const lightTheme = {
  background: "#f5f5f5",
  text: "black",
  h1_color: "gray",
  opacity: 0.87,
  action: "gray",
};

export const darkTheme = {
  background: "#121212",
  text: "#f5f5f5",
  h1_color: "gray",
  opacity: 0.87,
  action: "gray",
};
