import React from "react";
import { useLoaderData } from "react-router-dom";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";

// import styled from "styled-components";

// const StyledImage = styled.img`
//   width: 100%;
//   margin-bottom: 50px;
// `;

// const RemoveExtension = (filename) => {
//   const regex = /\.[^.]*$/;
//   return filename.replace(regex, "") + ".webp";
// };

//Find and pick relevant photo
// const MyImage = (obj) => {
//   //const found= obj.p.images.find((element) => element.id === obj.mdTag.src);
//   console.log(obj);
//   const found = obj.p.images.find((element) => element.id === obj.mdTag.src);

//   if (found === undefined) {
//     return <p>Can't find image @ {obj.mdTag.src}</p>;
//   } else {
//     const found2 = RemoveExtension(found.id.replace("img/", ""));

//     const img = require(`../content/projects/${obj.p.id}/${found.id}`);
//     const smallImg = require(`../content/projects/${obj.p.id}/img/small/${found2}`);
//     const mediumImg = require(`../content/projects/${obj.p.id}/img/medium/${found2}`);
//     const largeImg = require(`../content/projects/${obj.p.id}/img/large/${found2}`);
//     return (
//       <div>
//         <StyledImage
//           src={img}
//           srcSet={`${smallImg} 400w, ${mediumImg} 800w, ${largeImg} 1400w`}
//           loadoing="lazy"
//           alt=""
//         />
//         {/* <StyledCaption>{obj.mdTag.alt}</StyledCaption> */}
//         {/* <ImageCaption>{obj.mdTag.alt}</ImageCaption> */}
//       </div>
//     );
//   }
// };

// //extract data from
// const extractData = async (p, s) => {
//   const found = await projects.find((element) => element.id === p);
//   if (found === undefined) {
//     console.log("problem!");
//     return;
//   }
//   let mdFile = require(`../content/projects/${found.id}/${found.mdFile}`);

//   const response = await fetch(mdFile);
//   const markdown = await response.text();
//   //remove metaData
//   const newMarkdown = markdown.replace(
//     /^---[\n\r](((?!---).|[\n\r])*)[\n\r]---$/m,
//     ""
//   );

//   s({ id: found.id, year: found.year, md: newMarkdown, images: found.img });

//   return "Helooooo";
// };

const Project = () => {
  const loadedData = useLoaderData();
  const newMarkdown = loadedData.replace(
    /^---[\n\r](((?!---).|[\n\r])*)[\n\r]---$/m,
    ""
  );

  // const [projectData] = useState({
  //   id: "",
  //   year: "",
  //   project: "",
  //   md: null,
  //   images: [],
  // });

  // const renderers = {
  //   image: (tag) => {
  //     return <MyImage p={projectData} mdTag={tag} />;
  //     // return <div></div>;
  //   },
  // };
  return <ReactMarkdown children={newMarkdown}></ReactMarkdown>;
};

export default Project;
