import React from "react";
import styled from "styled-components";
import { useLoaderData } from "react-router-dom";
import ReactMarkdown from "react-markdown";
import { LinkRenderer } from "./SupportComponents";
const frikk_c = require("../content/files/about/img/frikk_bw.jpg");
const frikk_c_small = require("../content/files/about/img/compressed/image_200.webp");
const frikk_c_medium = require("../content/files/about/img/compressed/image_400.webp");
const frikk_c_large = require("../content/files/about/img/compressed/image_600.webp");
//const frikk_c_large = require("../content/files/about/img/frikk_bw.jpg");

const AboutWrapper = styled.div`
  margin-top: 5vh;
  display: grid;
  grid-template-columns: 50% auto;
  gap: 10%; /* Gap between the columns */

  /* Styles for iPad and smaller screens */
  @media screen and (max-width: 1024px) {
    grid-template-columns: 100%;
    gap: 0%;
  }
`;

const FlexPhoto = styled.div`
  display: flex;
  align-items: center;
  padding: 15%;
  //border-style: dotted;
  order: 1;

  /* Tablets */
  @media only screen and (min-width: 768px) and (max-width: 1023px) {
    order: 0;
    padding: 25%;
    padding-top: 5%;
    padding-bottom: 10%;
  }
  /* Smartphones */
  @media only screen and (max-width: 767px) {
    order: 0;
    padding: 10%;
  }

  // @media screen and (max-width: 1024px) {
  //   padding: 15%;
  //   order: 0;
  // }
`;

const TextWrap = styled.div`
  // border-style: dotted;

  @media screen and (max-width: 1024px) {
    order: 1;
  }
`;

const StyledImg = styled.img`
  width: 100%;
  max-width: 350px;
  object-fit: cover;
  filter: grayscale(100%);
`;

const File = () => {
  const loadedData = useLoaderData();
  var filteredMD = loadedData.replace(
    /^---[\n\r](((?!---).|[\n\r])*)[\n\r]---$/m,
    ""
  );

  return (
    <div>
      {/* <h3>This is a file: {test}</h3> */}
      {/* <ReactMarkdown children={test} renderers={renderers} /> */}
      <ReactMarkdown components={{ a: LinkRenderer }}>
        {filteredMD}
      </ReactMarkdown>
      {/* <img src={test}></img> */}
    </div>
  );
};

const Aboutv2 = () => {
  return (
    <AboutWrapper>
      <TextWrap>
        <File />
      </TextWrap>
      <FlexPhoto>
        <StyledImg
          src={frikk_c}
          srcSet={`${frikk_c_small} 200w, ${frikk_c_medium} 400w, ${frikk_c_large} 600w`}
          sizes="(max-width:300px) 100px, (max-width: 600px) 200px,  600px"
        />
      </FlexPhoto>
    </AboutWrapper>
  );
};
//srcSet={`${smallImg} 400w,  ${mediumImg} 800w, ${largeImg} 1400w`
export default Aboutv2;
