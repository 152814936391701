import React from "react";
import data from "../content/test.json";
import styled from "styled-components";
import tempPhoto from "../content/projects/interactive_cad/img/20210625-DSC_2839.jpg";

const GetProjects = (file) => {
  const projectFile = file.filter((entry) => {
    return entry.type === "project";
  });
  return projectFile;
};

const SortProjects = (file) => {
  file.sort((a, b) => {
    return new Date(b.entryDate) - new Date(a.entryDate);
  });
  return file;
};

// const RemoveExtension = (filename) => {
//   const regex = /\.[^.]*$/;
//   var newName = filename.replace(regex, "") + ".webp";
//   return newName;
// };

// const MyImage = (obj) => {
//   //console.log(obj.data.thumbnailPath)
//   if (obj.data.thumbnailPath === undefined) {
//     return <p>bad path</p>;
//   } else {
//     //clean thumbnail
//     const newThumbnail = obj.data.thumbnailPath.replace("img/", "");
//     var fileName = RemoveExtension(newThumbnail);

//     // const image = require(`../projects/${obj.data.id}/img/${newThumbnail}`);
//     const smallImg = require(`../projects/${obj.data.id}/img/small/${fileName}`);
//     const mediumImg = require(`../projects/${obj.data.id}/img/medium/${fileName}`);
//     const largeImg = require(`../projects/${obj.data.id}/img/large/${fileName}`);

//     return (
//       <ProjectThumbnail
//         src={largeImg}
//         srcSet={`${smallImg} 400w,  ${mediumImg} 800w, ${largeImg} 1400w`}
//         //sizes = "(min-width: 750px) 100w, 30w"
//         //loadoing = "lazy"
//       ></ProjectThumbnail>
//     );
//   }

//   // const found = obj.img.find((element) => element.id === obj.mdTag.src);
//   // if (found == undefined) {
//   //   return <p>Can't find image @ {obj.mdTag.src}</p>;
//   // } else {

//   // }
// };

const StyledWall = styled.div`
  display: grid;
  %border: dotted;
  grid-template-columns: minmax(auto, 500px) auto;
  grid-gap: 25%;
  filter: grayscale(100%);
  min-height: 250px;
  &:hover {
    color: orange;
    filter: grayscale(0 %);
  }
`;
const StyledWall2 = styled.div`
  display: grid;
  %border: dotted;
  max-width:
  min-height: 250px;

`;

const StyledImg = styled.img`
  object-fit: contain;
  width: 100%;
  height: 100%;
  :hover {
    color: "yellow";
  }
`;

const ProjectGallery = (props) => {
  // let { url } = useRouteMatch();

  //var projects = GetProjects(data);
  const projects = GetProjects(data);
  const sortedProjects = SortProjects(projects);

  return (
    <div>
      <div style={{ height: "200px" }}></div>
      <StyledWall2>
        {sortedProjects.map((p) => {
          return (
            <StyledWall key={p.id}>
              <StyledImg src={tempPhoto}></StyledImg>
              <div>
                <h1>{p.title}</h1>
                <h3>{p.entryDate}</h3>
                <p>{p.subtitle}</p>
              </div>
            </StyledWall>
          );
        })}
      </StyledWall2>
    </div>
  );
};

export default ProjectGallery;

// {projects.map((p) => {
//   return (
//       <h1>{p.title}</h1>
//       <p>{p.entryDate}</p>
//       <p>{p.subtitle}</p>
//   );
// })}
