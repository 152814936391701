import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import Header from "./Components/Header";
import { Helmet } from "react-helmet";

import { ThemeProvider } from "styled-components";

import { lightTheme, darkTheme } from "./Components/Styles/Theme";
import GlobalStyles, { Wrapper } from "./Components/Styles/globalStyles";

function App() {
  //define app states
  const [darkMode, setDarkMode] = useState(false);

  const selectMode = (mode) => {
    if (mode === "dark") {
      setDarkMode(true);
    } else {
      setDarkMode(false);
    }
  };

  //used by svg icon in header to flip state
  const flipState = () => {
    setDarkMode(!darkMode);
  };

  //check for dark mode
  useEffect(() => {
    //monitor change
    // window
    //   .matchMedia("(prefers-color-scheme: dark)")
    //   .addEventListener("change", (e) =>
    //     selectMode(e.matches ? "dark" : "light")
    //   );

    // //set on first load
    selectMode(
      window.matchMedia("(prefers-color-scheme: dark)").matches
        ? "dark"
        : "light"
    );
    console.log("effect triggered");

    return () => {
      window
        .matchMedia("(prefers-color-scheme: dark)")
        .removeEventListener("change", () => {});
    };
  }, []);
  //save theme for Helmet
  const theme = darkMode ? darkTheme : lightTheme;

  return (
    <ThemeProvider theme={darkMode ? darkTheme : lightTheme}>
      <Helmet>
        <meta name="theme-color" content={theme.background} />
      </Helmet>
      <GlobalStyles />

      <Wrapper className="wrapper">
        <Header switchState={flipState} state={darkMode} />
        <Outlet />
      </Wrapper>
    </ThemeProvider>
  );
}

export default App;
